<template>
  <div id="login" class="center">
    <div class="wrap">
      <div class="title">管理员登录</div>
      <a-input v-model:value="loginForm.username" placeholder="请输入用户名">
        <template #prefix><UserOutlined style="color:rgba(0,0,0,.25)"/></template>
      </a-input>
      <a-input @pressEnter="login" type="password" v-model:value="loginForm.password" placeholder="请输入密码">
         <template #prefix><LockOutlined style="color:rgba(0,0,0,.25)"/></template>
      </a-input>
      <div @click="login" class="login-btn">立即登录</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { userLogin } from "@/api"
import { message } from 'ant-design-vue';
import { setCookie } from "@/utils/cookie"
import router from "@/router"
import { useStore } from "vuex"

export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const store = useStore();
    const loginForm = reactive({
      username: "",
      password: ""
    })

    const login = () => {
      if (loginForm.username == "") {
        return message.warning("请输入用户名！");
      }
      if (loginForm.password == "") {
        return message.warning("请输入密码！");
      }
      userLogin(loginForm).then((res: any) => {
        if (res.code == 500) {
          loginForm.password = "";
          return message.error("密码错误！");
        }
        setCookie("user", res.data.data.user);
        setCookie("token", res.data.data.token);
        message.success("登录成功");
        store.commit("login/loginSign", true);
        router.push("/admin");
      })
    }
    onMounted(() => {
      if (store.state.login.isLogin) {
        router.push("/admin");
      }
    })

    return {
      loginForm,
      login
    }
  }

})
</script>

<style lang="scss" scoped>
  #login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;

    .wrap {
      width: 30%;
      margin-bottom: 100px;

      .title {
        text-align: center;
        font-weight: 600;
        font-size: 28px;
      }
      
      > span {
        margin-top: 20px;
        height: 40px;
      }

      .login-btn {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        cursor: pointer;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        background: #2254f4;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        background: linear-gradient(270deg,#2254f4,#406dff);
        box-shadow: 0 12px 30px 0 rgba(34,84,244,.2);
      }
    }
  }

  // 移动端
  @media screen and (max-width: 800px) {
    #login {

      .wrap {
        width: 90%;
      }
    }
  }
</style>