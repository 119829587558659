
import { defineComponent, onMounted, reactive } from "vue";
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { userLogin } from "@/api"
import { message } from 'ant-design-vue';
import { setCookie } from "@/utils/cookie"
import router from "@/router"
import { useStore } from "vuex"

export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const store = useStore();
    const loginForm = reactive({
      username: "",
      password: ""
    })

    const login = () => {
      if (loginForm.username == "") {
        return message.warning("请输入用户名！");
      }
      if (loginForm.password == "") {
        return message.warning("请输入密码！");
      }
      userLogin(loginForm).then((res: any) => {
        if (res.code == 500) {
          loginForm.password = "";
          return message.error("密码错误！");
        }
        setCookie("user", res.data.data.user);
        setCookie("token", res.data.data.token);
        message.success("登录成功");
        store.commit("login/loginSign", true);
        router.push("/admin");
      })
    }
    onMounted(() => {
      if (store.state.login.isLogin) {
        router.push("/admin");
      }
    })

    return {
      loginForm,
      login
    }
  }

})
